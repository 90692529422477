<template>
    <div>
        <AddDrawer />
    </div>
</template>

<script>
import store from "./store/index"
import AddDrawer from './components/AddDrawer.vue'
export default {
    components: {
        AddDrawer
    },
    beforeCreate() {
        if(!this.$store.hasModule('facilities')) {
            this.$store.registerModule("facilities", store)
        }
    }
}
</script>